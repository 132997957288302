import React from 'react'

import Paper from '@material-ui/core/Paper'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { useDispatch } from 'react-redux'

import {
  deleteNewProduct,
  deleteProductVariation,
  selectSettingToEdit,
} from 'product/actions'
import { openInNewTab } from 'helper'
import { set } from 'lodash'

const styles = () => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

const Price = ({ product, k }) => {
  if (!product) return null
  if (!product[k]) return null
  return (
    <p>
      {k}: ${product[k]}
    </p>
  )
}

const sortIcon = <ArrowDownward />

const columns = (show_facility, deleteLine, _selectSettingToEdit) =>
  memoize(() => [
    {
      name: show_facility ? 'Variation Name' : 'Name',
      selector: 'name',
      center: true,
      cell: product =>
        `${product.name} ${
          product.field_types?.length
            ? `Field types: ${product.field_types
                .map(f => f.field_type?.name)
                .join(' / ')}`
            : ''
        }`,
      sortFunction: (productA, productB) => productA.name < productB.name,
    },
    {
      name: 'Variations',
      selector: 'variations',
      center: true,
      cell: product => <b>{product.variations?.length || 0}</b>,
      omit: show_facility,
    },
    {
      name: show_facility ? 'Facility' : 'GL',
      selector: 'gl',
      center: true,
      cell: product => (
        <div>
          {show_facility ? product.facility.name : product.gl.code}
          <br />
          <small>{!show_facility && product.gl.product_type.name}</small>
        </div>
      ),
    },
    {
      name: 'Prices',
      selector: 'deposit',
      center: true,
      cell: p => (
        <div>
          <Price product={p} k={'base_price'} />
          <Price product={p} k={'peak_price'} />
          <Price product={p} k={'off_peak_price'} />
          <Price product={p} k={'early_bird_price'} />
          <Price product={p} k={'team_price'} />
          <Price product={p} k={'individual_price'} />
          <Price product={p} k={'deposit_price'} />
        </div>
      ),
    },
    {
      name: 'Fees',
      selector: 'fees',
      center: true,
      cell: p => (
        <div>
          {p.fees?.map((f, key) => (
            <p key={key}>{`${f.fee.name} - ${f.fee.value}%`}</p>
          ))}
        </div>
      ),
    },
    {
      name: 'Excluded facilities',
      selector: 'fees',
      center: true,
      grow: 3,
      cell: p => {
        !show_facility && (
          <div>
            <p>{`${p.facilities?.map(f => f + '  ')}`}</p>
          </div>
        )
      },
    },
    {
      name: 'Taxes',
      selector: 'taxes',
      center: true,
      cell: p => (
        <div>
          {p.taxes?.map((f, key) => (
            <p key={key}>{`${f.tax.name} - ${f.tax.value}%`}</p>
          ))}
        </div>
      ),
    },
    {
      name: 'Actions',
      selector: 'actions',
      center: true,
      cell: product => (
        <>
          <IconButton
            onClick={() =>
              !show_facility
                ? openInNewTab(`/newproduct/${product.ID}?tab=base`)
                : _selectSettingToEdit(product)
            }
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton onClick={() => deleteLine(product.ID)}>
            <DeleteIcon
              color="secondary"
              fontSize="small"
              className="del-btn"
            />
          </IconButton>
        </>
      ),
    },
  ])

const ProductsTable = ({ products, facilities, show_facility }) => {
  const dispatch = useDispatch(),
    _deleteNewProduct = id => dispatch(deleteNewProduct(id)),
    _deleteProductVariation = id => dispatch(deleteProductVariation(id)),
    _selectSettingToEdit = variation => dispatch(selectSettingToEdit(variation))

  let data = products?.map(product => {
    console.log(product.facilit)
    return {
      ...product,
      facilities: facilities
        ?.filter(f => product.excluded_facilities?.includes(f.value))
        .map(f => f.nickname),
    }
  })
  console.log(data)

  return (
    <div>
      <Paper elevation={0}>
        <DataTable
          columns={columns(
            show_facility,
            show_facility ? _deleteProductVariation : _deleteNewProduct,
            _selectSettingToEdit
          )()}
          data={data}
          highlightOnHover
          defaultSortField="facility"
          defaultSortAsc={false}
          sortIcon={sortIcon}
          fixedHeader
          customStyles={{
            rows: {
              style: {
                textAlign: 'center',
                paddingTop: 50,
                paddingBottom: 50,
              },
            },
          }}
        />
      </Paper>
    </div>
  )
}

export default ProductsTable
