import React from 'react'
import { connect } from 'react-redux'

import moment from 'moment'
import memoize from 'memoize-one'
import { withStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import DataTable from 'react-data-table-component'
import IconButton from '@material-ui/core/IconButton'
import Chip from '@material-ui/core/Chip'
import FileCopyIcon from '@material-ui/icons/Description'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import DeleteIcon from '@material-ui/icons/Delete'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import EditIcon from '@material-ui/icons/Edit'
import Tooltip from '@material-ui/core/Tooltip'

import { unixToDate } from '../../helper'
import { deleteClassRegistration, deleteTemporaryAssistent } from './../actions'
import { openDeleteModal } from 'common/actions'
import { colors } from 'consts'
import { niceNumber, getHubspot } from 'helper'
import { actions } from 'classes/redux'

const sortIcon = <ArrowDownward />
function getAgeStr(input) {
  let dob
  if (/^\d{2}\/\d{2}\/\d{4}$/.test(input)) {
    dob = moment(input, 'MM/DD/YYYY')
  } else if (/^\d+$/.test(input)) {
    dob = moment.unix(input)
  }
  if (dob) {
    const now = new Date() // Get the current date
    let age = now.getFullYear() - dob.year() // Calculate the year difference
    const monthDiff = now.getMonth() - dob.month() // Calculate the month difference
    const dayDiff = now.getDate() - dob.date() // Calculate the day difference

    // Adjust age if the birthday hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--
    }
    return `${dob.format('M/D/YYYY')}  (${age} years)`
  }
}

const columns = memoize(
  (deleteClassRegistration, openChangeClass, openUpdateAttendee) => [
    {
      name:'Parent',
      selector: 'Parent',
      center:true,
      grow: 2,
      cell: row => (
        row.parent.ID && row.parent.first_name && row.parent.last_name ? (
          <Link href={`/customer/${row.parent.ID}`} style={{ textAlign: 'center' }}>
            {row.parent.first_name} {row.parent.last_name}
          </Link>
        ) : null
      )
    },
    {
      name: 'Firstname',
      selector: 'Firstname',
      center: true,
      grow: 2,
      cell: row => (
        <div style={{ textAlign: 'center' }}>{row.customer.first_name}</div>
      ),
    },
    {
      name: 'Lastname',
      selector: 'Lastname',
      center: true,
      grow: 2,
      cell: row => (
        <div style={{ textAlign: 'center' }}>{row.customer.last_name}</div>
      ),
    },
    {
      name: 'Uniform Size',
      selector: 'Uniformsize',
      center: true,
      grow: 2,
      cell: row => (
        <div style={{ textAlign: 'center' }}>{row.uniform_size}</div>
      ),
    },
    {
      name: 'Contact',
      selector: 'Contact',
      center: true,
      grow: 2,
      cell: row => (
        <div style={{ textAlign: 'center' }}>
          <img
            src="https://ps.w.org/leadin/assets/icon-256x256.png?rev=2785153"
            onClick={() => getHubspot(row.parent?.ID || row.customer?.ID)}
            width="15"
            style={{ marginLeft: 10, cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      name: 'Balance',
      selector: 'Balance',
      center: true,
      grow: 1,
      cell: row => (
        <Chip
          size="small"
          label={<b>${niceNumber(row.invoice.balance)}</b>}
          style={{
            color:
              row.invoice.balance > 0 ? colors.red_strong : colors.green_strong,
            backgroundColor:
              row.invoice.balance > 0 ? colors.red_mild : colors.green_mild,
          }}
        />
      ),
      sortFunction: (rowA, rowB) => rowA.invoice.balance - rowB.invoice.balance,
    },
    {
      name: 'Membership exp',
      selector: 'MembershipExp',
      center: true,
      grow: 3,
      cell: row => {
        let membership =
          row.parent?.member?.memberships.find(
            membership => membership.sub_customer_id === row.customer?.ID
          ) ||
          (row.parent?.member?.memberships.length > 0 &&
            row.parent?.member?.memberships[0])
        return (
          <div
            style={{
              color:
                membership && membership.valid_until > moment().unix()
                  ? 'black'
                  : 'red',
            }}
          >
            {membership
              ? moment.unix(membership.valid_until).format('M/D/YYYY')
              : 'no membership'}
          </div>
        )
      },
    },
    {
      name: 'DoB',
      selector: 'DoB',
      center: true,
      grow: 3,
      cell: row => {
        let dob = row.details.dob
        return <div>{getAgeStr(dob)}</div>
      },
    },
    {
      name: 'Action',
      selector: 'Action',
      center: true,
      grow: 2,
      cell: row => (
        <div style={{ textAlign: 'center' }}>
          {(!row.invoice || row.invoice.paid === 0) && (
            <IconButton onClick={() => deleteClassRegistration(row.ID)}>
              <DeleteIcon
                fontSize="small"
                style={{ color: colors.red_strong }}
              />
            </IconButton>
          )}
          <Link href={`/invoice/${row.invoice_id}`} target="_blank">
            <IconButton style={{ padding: 5 }}>
              <FileCopyIcon
                fontSize="small"
                style={{
                  color: colors.blue_strong,
                }}
              />
            </IconButton>
          </Link>
          <Tooltip title="Move to other class">
            <IconButton
              style={{ padding: 5 }}
              onClick={() => openChangeClass(row.ID)}
            >
              <ImportExportIcon
                fontSize="small"
                style={{
                  color: colors.yellow_strong,
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add/Change Uniform Size">
            <IconButton
              style={{ padding: 5 }}
              onClick={() => openUpdateAttendee(row)}
            >
              <EditIcon
                fontSize="small"
                style={{
                  color: colors.blue_strong,
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ]
)

const columns_trials = memoize(deleteAssistent => [
  {
    name:'Parent',
    selector: 'Parent',
    center:true,
    grow: 2,
    cell: row => (
      row.parent.ID && row.parent.first_name && row.parent.last_name ? (
        <Link href={`/customer/${row.parent.ID}`} style={{ textAlign: 'center' }}>
          {row.parent.first_name} {row.parent.last_name}
        </Link>
      ) : null
    )
  },
  {
    name: 'Firstname',
    selector: 'Firstname',
    center: true,
    grow: 2,
    cell: row => (
      <div style={{ textAlign: 'center' }}>{row.customer.first_name}</div>
    ),
  },
  {
    name: 'Lastname',
    selector: 'Lastname',
    center: true,
    grow: 2,
    cell: row => (
      <div style={{ textAlign: 'center' }}>{row.customer.last_name}</div>
    ),
  },
  {
    name: 'DoB',
    selector: 'DoB',
    center: true,
    grow: 3,
    cell: row => {
      if (!row.customer.details) return
      try {
        let parsedDetails = JSON.parse(row.customer.details)
        return <div>{getAgeStr(parsedDetails.dob)}</div>
      } catch (e) {
        console.log(e)
      }
    },
  },
  {
    name: 'Uniform Size',
    selector: 'Uniformsize',
    center: true,
    grow: 2,
    cell: row => <div style={{ textAlign: 'center' }}>{row.uniform_size}</div>,
  },
  {
    name: 'Contact',
    selector: 'Contact',
    center: true,
    grow: 1,
    cell: row => (
      <div style={{ textAlign: 'center' }}>
        <img
          src="https://ps.w.org/leadin/assets/icon-256x256.png?rev=2785153"
          onClick={() => getHubspot(row.parent?.ID || row.customer?.ID)}
          width="15"
          style={{ marginLeft: 10, cursor: 'pointer' }}
        />
      </div>
    ),
  },
  {
    name: 'Balance',
    selector: 'Balance',
    center: true,
    grow: 1,
    cell: row => (
      <Chip
        size="small"
        label={<b>Free spot</b>}
        style={{
          color: colors.green_strong,
          backgroundColor: colors.green_mild,
        }}
      />
    ),
    sortFunction: (rowA, rowB) => rowA.invoice.balance - rowB.invoice.balance,
  },
  {
    name: 'Action',
    selector: 'Action',
    center: true,
    grow: 2,
    cell: row => (
      <div style={{ textAlign: 'center' }}>
        {(!row.invoice || row.invoice.paid === 0) && (
          <IconButton onClick={() => deleteAssistent(row.ID)}>
            <DeleteIcon fontSize="small" style={{ color: colors.red_strong }} />
          </IconButton>
        )}
      </div>
    ),
  },
])

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

const ParticipantsTable = ({
  participants,
  deleteClassRegistration,
  deleteAssistant,
  openChangeClass,
  openUpdateAttendee,
  events,
}) => (
  <>
    <Paper elevation={0}>
      <DataTable
        columns={columns(
          deleteClassRegistration,
          openChangeClass,
          openUpdateAttendee
        )}
        data={participants}
        highlightOnHover
        defaultSortField="date"
        defaultSortAsc={false}
        sortIcon={sortIcon}
        pagination
        striped
        fixedHeader
        paginationPerPage={50}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
      />
    </Paper>

    {events.map((event, index) => {
      if (event.assistents.length > 0) {
        return (
          <Paper elevation={0}>
            <h3
              style={{
                padding: '10px',
                color: colors.blue_strong,
                textAlign: 'center',
              }}
            >
              Trials {unixToDate(event.start_date).format('M/D/YYYY')}
            </h3>
            <DataTable
              columns={columns_trials(deleteAssistant)}
              data={event.assistents}
              highlightOnHover
              defaultSortField="date"
              defaultSortAsc={false}
              sortIcon={sortIcon}
              pagination
              striped
              fixedHeader
              paginationPerPage={50}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
            />
          </Paper>
        )
      }
    })}
  </>
)

const mapStateToProps = (state, ownProps) => ({
  participants: state.classes.classInfo.attendees,
  events: state.classes.classInfo.events,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteClassRegistration: id =>
    dispatch(
      openDeleteModal('class registration', () => deleteClassRegistration(id))
    ),
  deleteAssistant: id =>
    dispatch(
      openDeleteModal('Temporary assistent', () => deleteTemporaryAssistent(id))
    ),
  openChangeClass: attendee_id => {
    dispatch(actions.editChangeClass({ key: 'open', value: true }))
    dispatch(
      actions.editChangeClass({ key: 'attendee_id', value: attendee_id })
    )
  },
  openUpdateAttendee: attendee => {
    dispatch(actions.openUpdateAttendeeSize(attendee))
  },
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withStyles(styles)(ParticipantsTable))
