import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { getFields, getEvents, getConflicts } from './../actions'

import EventDialog from './../components/EventDialog'
import Calendar from './../components/Calendar'

class ManageCalendar extends React.Component {
  componentDidMount() {
    const queryParameters = new URLSearchParams(window.location.search)
    const day = queryParameters.get('day')
    console.log(day)
    this.props.getFields()
    this.props.getEvents(moment().startOf('day'))
    let now = moment().startOf('day')
    this.props.getConflicts(now, now.clone().add(20, 'd'))
  }
  render() {
    let conflictsIDs = this.props.conflicts?.map(e => e.ID),
      conflictsInPage = this.props.events?.filter(e =>
        conflictsIDs.includes(e.ID)
      )
    return (
      <div>
        {this.props.conflicts?.length > 0 && (
          <>
            <h4>
              ⚠️ {this.props.conflicts?.length} Calendar conflicts found! in the
              next 20 days. ⚠️ {conflictsInPage?.length} Calendar conflicts in
              this page
            </h4>
            <a
              href={`/calendar?date=${moment(
                this.props.conflicts[0].ConflictTime
              ).format('M/D/YYYY')}`}
            >
              Go to first conflict date
            </a>
          </>
        )}
        <EventDialog />
        <Calendar search={this.props.location.search} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  conflicts: state.calendar.conflicts,
  events: state.calendar.events,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getFields: () => dispatch(getFields()),
  getEvents: day => dispatch(getEvents(day)),
  getConflicts: (start, end) => dispatch(getConflicts(start, end)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageCalendar)
