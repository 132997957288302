import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import Select from 'react-select'

import FastTextField from 'common/components/FastTextField'

import { getProductTypes, getSeasons, saveGL } from 'resources/actions'
import { actions } from 'resources/redux'

const NewGLDialog = () => {
  const { open, code, product_type_id, season_id } = useSelector(
      state => state.resources.gl
    ),
    product_types = useSelector(state => state.resources.product_types).map(
      p => ({
        label: p.name,
        value: p.ID,
      })
    ),
    seasons = useSelector(state => state.resources.seasons).map(p => ({
      label: p.name,
      value: p.ID,
    })),
    dispatch = useDispatch(),
    updateGL = (k, v) => dispatch(actions.updateGL({ key: k, value: v })),
    handleClose = () => updateGL('open', false),
    _saveGL = () => dispatch(saveGL())

  useEffect(() => {
    dispatch(getProductTypes())
    dispatch(getSeasons())
  }, [])
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Create GL
        <IconButton
          style={{
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={7}>
            <FastTextField
              label="Code *"
              variant="outlined"
              value={code}
              onChange={e => updateGL('code', e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              options={product_types}
              placeholder="Product type *"
              value={
                product_type_id &&
                product_types.find(p => p.value === product_type_id)
              }
              onChange={v => updateGL('product_type_id', v.value)}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              options={seasons}
              placeholder="Season"
              value={season_id && seasons.find(p => p.value === season_id)}
              onChange={v => updateGL('season_id', v.value)}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => _saveGL()} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewGLDialog
