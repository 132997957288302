import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Select from 'react-select'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'
import moment from 'moment'

import {
  changeFilterDetails,
  toggleModal,
  toggleTournamentModal,
  getLeagues,
} from './../actions'
import { colors } from './../../consts'
import { unixToDateTime, momentDateTimeSecondsToUnix } from 'helper'

const sub_product = [
    { label: 'Adult Leagues', value: 3 },
    { label: 'Adult Tournament', value: 4 },
    { label: 'Youth League', value: 8 },
    { label: 'Youth Tournament', value: 9 },
  ],
  yes_no = [
    { label: 'Active: Yes', value: 1 },
    { label: 'Active: No', value: 0 },
  ]

class ResourceDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      focusedInput: null,
    }
  }
  render() {
    return (
      <div>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={2} style={{ width: '100%' }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Filter leagues..."
              value={this.props.query}
              onChange={e =>
                this.props.changeFilterDetails('query', e.target.value)
              }
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Select
              options={this.props.facilities}
              placeholder="Facility"
              onChange={v => this.props.changeFilterDetails('facility_id', v)}
              isClearable
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              isMulti
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Select
              options={yes_no}
              placeholder="Active"
              value={yes_no.find(p => p.value === this.props.active)}
              onChange={v =>
                this.props.changeFilterDetails('active', v && v.value)
              }
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </Grid>

          <Grid item xs={3} style={{
            display:'flex',
            flexDirection:'row',
          }}>
            <DatePickerComponent
              id="start_date"
              placeholder="Start date"
              selectedValue={this.props.start_date ? unixToDateTime(this.props.start_date) : null}
              onChange={e => {
                this.props.changeFilterDetails(
                  'start_date',
                  momentDateTimeSecondsToUnix(
                    moment(e.value).hour(0).minute(0).second(0)
                  )
                )
              }}
              style={{ textAlign: 'center' }}
            />

            <DatePickerComponent
              id="end_date"
              placeholder="End date"
              selectedValue={this.props.end_date ? unixToDateTime(this.props.end_date) : null}
              onChange={e => {
                this.props.changeFilterDetails(
                  'end_date',
                  momentDateTimeSecondsToUnix(
                    moment(e.value).hours(23).minutes(59).seconds(59)
                  )
                )
              }}
              style={{ textAlign: 'center' }}
            />
          </Grid>

          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Select
              options={sub_product}
              placeholder="Product"
              value={
                this.props.product_type_id &&
                sub_product.find(p => p.value === this.props.product_type_id)
              }
              onChange={v =>
                this.props.changeFilterDetails('product_type_id', v && v.value)
              }
              isClearable
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Button
              style={{
                color: colors.blue_strong,
                backgroundColor: colors.blue_mild,
              }}
              component="span"
              onClick={() => this.props.getLeagues()}
            >
              Search
            </Button>
          </Grid>
          {this.props.is_admin && (
            <Grid item xs={2} style={{ textAlign: 'center' }}>
              <Button
                style={{
                  color: colors.blue_strong,
                  backgroundColor: colors.blue_mild,
                }}
                component="span"
                onClick={() => this.props.openLeagueModal()}
                size="small"
              >
                Add new league
              </Button>
            </Grid>
          )}
          {this.props.is_admin && (
            <Grid item xs={2} style={{ textAlign: 'center' }}>
              <Button
                style={{
                  color: colors.blue_strong,
                  backgroundColor: colors.blue_mild,
                }}
                component="span"
                onClick={() => this.props.openTournamentModal()}
                size="small"
              >
                Add new tournament
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.league.filter,
  facilities: state.common.filter_facilities,
  is_admin: state.user.is_admin,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeFilterDetails: (k, v) => dispatch(changeFilterDetails(k, v)),
  openLeagueModal: () => dispatch(toggleModal(true)),
  openTournamentModal: () => dispatch(toggleTournamentModal(true)),
  getLeagues: () => dispatch(getLeagues()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResourceDropdown)
