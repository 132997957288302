import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import ProfileIcon from '@material-ui/icons/AssignmentInd'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import { Button } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import Transfert from '@material-ui/icons/MoveToInbox'

import { openInNewTab } from './../../../helper'
import { unixToDateTime } from 'helper'
import { changeLoading } from 'common/actions'
import { actions } from './../../redux'

const Membership = props => {
  let { customer } = props
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
      spacing={4}
    >
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Button
          className="save-btn"
          style={{ marginLeft: 10 }}
          onClick={() => {
            props.openAddMembership(true)
          }}
        >
          Add Membership
        </Button>
        <Paper elevation={0} style={{ marginTop: '10px' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Buy Date
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Expiration Date
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Holder fullname
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Holder Gender
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Holder DoB
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Update Holder Details
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Edit Membership
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customer?.customer?.member?.memberships?.map(membership => {
                let holder = customer?.family.find(
                  familyMember =>
                    familyMember.child.ID === membership.sub_customer_id
                )?.child
                if (!holder && !customer?.customer?.is_kid) {
                  holder = customer?.customer
                }
                console.log(holder)
                let details = {}
                try {
                  details = holder.details && JSON.parse(holder.details)
                } catch (error) {}
                return (
                  <TableRow key={membership.ID}>
                    <TableCell style={{ textAlign: 'center' }}>
                      {moment(membership.CreatedAt).format('MMMM D, YYYY')}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {unixToDateTime(membership.valid_until).format(
                        'MMMM D, YYYY'
                      )}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {holder
                        ? `${holder.first_name} ${holder.last_name}`
                        : 'Please check on parent profile'}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {details.gender}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {details.dob}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {holder && (
                        <IconButton
                          onClick={() =>
                            openInNewTab(`/customer/${holder?.ID}`)
                          }
                        >
                          <ProfileIcon color="primary" fontSize="small" />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {props.is_admin && !customer?.customer?.is_kid ? (
                        <IconButton
                          style={{ padding: 1 }}
                          onClick={() => {
                            props.setMembership(membership)
                          }}
                        >
                          <EditIcon color="primary" fontSize="small" />
                        </IconButton>
                      ) : (
                        <>
                          <p> GO to parent profile </p>
                          <IconButton
                            onClick={() =>
                              openInNewTab(
                                `/customer/${customer?.customer?.member?.customer_id}`
                              )
                            }
                          >
                            <Transfert color="primary" fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state, ownProps) => {
  const customer = state.customer
  return {
    is_admin: state.user.is_admin,
    customer,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeLoading: loading => dispatch(dispatch(changeLoading(loading))),
  setMembership: membership => {
    dispatch(
      actions.updateMembershipDialog({ key: 'membership', value: membership })
    )
    dispatch(
      actions.updateMembershipDialog({ key: 'open_update', value: true })
    )
  },
  openAddMembership: () => {
    dispatch(
      actions.updateMembershipDialog({ key: 'open_create', value: true })
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Membership)
