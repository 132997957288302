import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core'
import { initTerminalPayment, selectTerminal, getTerminalPaymentConfirmation } from '../actions';

const SquareTerminal = ({ amount, idempotency_key, onTerminalConfirmation }) => {

  const dispatch = useDispatch();
  const available_terminals = useSelector((state) => state.invoice.available_terminals);
  const state = useSelector((state) => state.invoice.terminal_payment_state)
  const payment_id = useSelector((state) => state.invoice.terminal_payment_id)


  const handleSelect = async () => {
    dispatch(selectTerminal());
  };
  
  const pay = async (index) => {
    const terminal_id = available_terminals[index].id
    const amount_float = parseFloat(amount);
    dispatch(initTerminalPayment(idempotency_key, terminal_id, amount_float))
  }

  const confirmPayment = async () => {
    dispatch(getTerminalPaymentConfirmation(onTerminalConfirmation, payment_id))
  }

  useEffect(() => {
    if (state === "processing" && payment_id) {
      confirmPayment();
    }
  }, [state, payment_id])
  
  return (
    <>
      <div id="card-container" style={{ minHeight: 89 }}>
        {!state && 
        <Button onClick={handleSelect} className="save-btn" style={{ marginBottom: 20 }}>
          Select Terminal
        </Button>
        }

        {state === 'selecting terminal' && (
          <div>
            {available_terminals.length > 0 ? (
              <ul style={{justifyContent:'center'}}>
                <h3 style={{ marginBottom: '20px' }}>Choose a Terminal</h3>
                <ul style={{ listStyleType: 'none', padding: '0', display: 'flex', flexWrap: 'wrap', justifyContent:'center' }}>
                  {available_terminals.map((terminal, index) => (
                    <li
                      key={index}
                      style={{
                        marginBottom: '15px',
                        listStyleType: 'none',
                      }}
                    >
                      <Button
                        onClick={() => {pay(index)}}
                        className="terminal-btn"
                        style={{
                          padding: '10px 20px',
                          backgroundColor: '#007BFF',
                          color: 'white',
                          borderRadius: '5px',
                          cursor: 'pointer',
                          margin: '0 10px',
                        }}
                      >
                        {terminal.nickname}
                      </Button>
                    </li>
                  ))}
                </ul>
              </ul>
            ) : (
              <>
                <p>No online terminals found</p>
                <Button onClick={handleSelect} className="save-btn" style={{ marginBottom: 20 }}>
                  Try Again
                </Button>
              </>
            )}
          </div>
        )}

        {state === 'processing' && <span>Waiting for Confirmation</span>}
        {state === 'confirmed' && <span>Payment Confirmed</span>}
        {state === 'not found' && 
        <>
          <span>We coudn't find payment confirmation</span>
          <Button onClick={confirmPayment} className="save-btn" style={{ marginBottom: 20 }}>
            Search for payment confirmation again
          </Button>
        </>}
      </div>
    </>
  )
}
  
  export default SquareTerminal
  