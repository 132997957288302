import { unixToDateTime, parseInvoice, momentDateTimeToUnix } from './../helper'
import { event_colors } from './../consts'
import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

const initialState = {
  step: 1,
  loading: false,
  fields: [],
  product_types: [],
  current_date: null,
  events: [],
  conflicts: [],
  tooltip: false,
  customers: [],
  event_details: {
    id: null,
    start_time: null,
    end_time: null,
    customer: null,
    comment: '',
    field_id: null,
    product_type_id: null,
    product_type: null,
    new_customer: false,
    edit: false,
    match: null,
    invoice: null,
    repeat: false,
    repeat_method: null,
    repeat_frequency: 1,
    repeat_days: [],
    end_method: null,
    end_count: 1,
    end_date: null,
    repeat_events: [],
    repeat_change: false,
    coach_id: null,
    video: null,
    team_a: '',
    team_b: '',
    attendances: [],
  },
  event_details_modal_open: false,
  soccernaut_participants: [],
  pickup_players: [],
  tab_menu: 'invoice',
  tab_customer: 'existing',
  health_checks: [],
  team_home: null,
  team_away: null,
}

const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
      getFields: (state, { payload }) => {
        state.fields = payload.fields
      },
      getCustomers: (state, { payload }) => {
        state.customers = payload.customers
      },
      setCurrentDate: (state, { payload }) => {
        state.current_date = unixToDateTime(payload.moment_start).format(
          'M/D/YYYY'
        )
      },
      getConflicts: (state, { payload }) => {
        state.conflicts = payload.conflicts
      },
      getEvents: (state, { payload }) => {
        let f = null
        state.events = payload.events.map(e => {
          const ptype = e.product_type.ID
          let event = e.product_type.name
          if (ptype === 10) event = e.comment
          let description = ''
          if (e.customer.first_name !== '') {
            description += `${e.customer.first_name} ${e.customer.last_name} - `
          }
          description += e.comment
          const extended_invoice = parseInvoice(e.invoice)
          let color = event_colors[ptype]
          if (e.match?.league?.color) color = e.match?.league?.color
          f = e.field
          return {
            ...e,
            Subject: event,
            Description: description,
            color,
            balance: extended_invoice.balance,
          }
        })
        // .concat(
        //   state.fields.map((f, i) => {
        //     let day = payload.day,
        //       _peak_start = `${day} ${
        //         payload.is_weekend
        //           ? f.weekend_peak_time_start
        //           : f.weekday_peak_time_start
        //       }`,
        //       _peak_end = `${day} ${
        //         payload.is_weekend
        //           ? f.weekend_peak_time_end
        //           : f.weekday_peak_time_end
        //       }`,
        //       peak_start = momentDateTimeToUnix(
        //         moment(_peak_start, 'MM/DD/YYYY HH')
        //       ),
        //       moment_peak_end = moment(_peak_end, 'MM/DD/YYYY HH')
        //     if (parseFloat(f.weekday_peak_time_end) < 12)
        //       moment_peak_end.add(1, 'd')
        //     let peak_end = momentDateTimeToUnix(moment_peak_end)
        //     return {
        //       ID: i + 1,
        //       Subject: 'Peak',
        //       color: '#fafafa',
        //       start_date: peak_start,
        //       end_date: peak_end,
        //       product_type_id: 5,
        //       field: f,
        //       field_id: f.ID,
        //       small: true,
        //     }
        //   })
        // )
        // .concat(
        //   state.fields.map((f, i) => {
        //     let day = payload.day,
        //       _peak_start = `${day} ${payload.is_weekend ? f.weekend_open_time : f.weekday_open_time}`,
        //       _peak_end = `${day} ${payload.is_weekend ? f.weekend_close_time : f.weekday_close_time}`,
        //       peak_start = momentDateTimeToUnix(
        //         moment(_peak_start, 'MM/DD/YYYY HH')
        //       ),
        //       moment_peak_end = moment(_peak_end, 'MM/DD/YYYY HH')
        //     if (parseFloat(f.weekday_peak_time_end) < 12)
        //       moment_peak_end.add(1, 'd')
        //     let peak_end = momentDateTimeToUnix(moment_peak_end)
        //     return {
        //       ID: i + 1,
        //       Subject: 'Open',
        //       color: '#85c4fc42',
        //       start_date: peak_start,
        //       end_date: peak_end,
        //       product_type_id: 5,
        //       field: f,
        //       field_id: f.ID,
        //       small: true,
        //     }
        //   })
        // )
      },
      getEvent: (state, { payload }) => {
        state.event_details = {
          ...state.event_details,
          ...payload.event,
          product_type: payload.event.product_type.name,
          start_time: payload.event.start_date,
          end_time: payload.event.end_date,
          date_parsed: unixToDateTime(payload.event.start_date).format(
            'M/D/YYYY'
          ),
        }
      },
      createEvent: (state, { payload }) => {
        const e = payload.event,
          start = unixToDateTime(e.start_date).toDate(),
          end = unixToDateTime(e.end_date).toDate(),
          event = e.product_type.name,
          ptype = e.product_type.ID
        let description = e.comment
        if (e.customer.first_name !== '') {
          description += ` (  ${e.customer.first_name} ${e.customer.last_name} )`
        }
        state.events = state.events.concat({
          ...e,
          Subject: event,
          StartTime: start,
          EndTime: end,
          Description: description,
          color: event_colors[ptype],
        })
        state.event_details = {
          ...state.event_details,
          ...e,
          product_type: event,
          id: e.ID,
        }
      },
      updateEvent: (state, { payload }) => {
        const updated_event = payload.event
        state.events = state.events.map(ev => {
          if (ev.ID === payload.id) {
            return {
              ...ev,
              comment: updated_event.comment,
              StartTime: unixToDateTime(updated_event.start_date).toDate(),
              EndTime: unixToDateTime(updated_event.end_date).toDate(),
              Description: updated_event.comment,
              customer: state.event_details.customer,
              field_id: updated_event.field_id,
            }
          }
          return ev
        })
      },
      updateNoShow: (state, { payload }) => {
        state.event_details = {
          ...state.event_details,
          no_show: payload.no_show,
        }
      },
      updateVideo: (state, { payload }) => {
        state.event_details = {
          ...state.event_details,
          video_visible: payload.video_visible,
        }
      },
      deleteEvent: (state, { payload }) => {
        state.events = state.events.filter(e => e.ID !== payload.event_id)
      },
      changeEventDetails: (state, { payload }) => {
        let new_details = {
            ...state.event_details,
            [payload.field]: payload.value,
          },
          step = state.step
        if (payload.field === 'reset') {
          new_details = { ...initialState.event_details }
          step = 1
        }
        if (payload.field === 'start_time') {
          new_details.repeat_days = [
            unixToDateTime(payload.value).day().toString(),
          ]
        }
        if (payload.field === 'start_time') {
          if (!new_details.end_time) {
            new_details.end_time = payload.value + 3600 // +1h by default
          } else {
            const existing_min_delta =
              state.event_details.end_time - state.event_details.start_time
            new_details.end_time = payload.value + existing_min_delta
          }
        }
        state.event_details = { ...new_details }
        state.step = step
      },
      changeCustomerAttendance: (state, { payload }) => {
        const index = state.event_details.attendances.findIndex(
          c => c.customer_id === payload.attendance.customer_id
        )
        if (index !== -1) {
          state.event_details.attendances[index] = payload.attendance
        }
      },
      updateSingleRecurring: (state, { payload }) => {
        state.event_details = {
          ...state.event_details,
          repeat_events: state.event_details.repeat_events.map(e => {
            if (e.ID === payload.id) {
              return { ...e, ...payload.event }
            }
            return e
          }),
        }
      },
      changeEventCustomerDetails: (state, { payload }) => {
        let field = payload.field,
          hubspot = false
        if (field.indexOf('hubspot') !== -1) {
          hubspot = true
          field = field.split('.')[1]
        }
        let new_customer = {
          ...state.event_details.customer,
        }
        if (hubspot) {
          new_customer.hubspot = {
            [field]: payload.value,
          }
        } else {
          new_customer[field] = payload.value
        }
        state.event_details = {
          ...state.event_details,
          customer: new_customer,
        }
      },
      getProductTypes: (state, { payload }) => {
        state.product_types = payload.product_types
      },
      changeStep: (state, { payload }) => {
        state.step = Math.min(Math.max(0, state.step + payload), 3)
      },
      toggleEventModal: (state, { payload }) => {
        state.event_details_modal_open = payload
      },
      toggleTooltip: (state, { payload }) => {
        console.log(payload)
        state.tooltip = payload
      },
      getProducts: (state, { payload }) => {
        state.products = payload.products.map(p => {
          let prices = []
          if (p.deposit_price !== 0) {
            prices.push({
              name: 'Deposit',
              value: p.deposit_price,
            })
          }
          if (p.early_bird_price !== 0) {
            prices.push({
              name: 'Early Bird',
              value: p.early_bird_price,
            })
          }
          if (p.individual_price !== 0) {
            prices.push({
              name: 'Individual',
              value: p.individual_price,
            })
          }
          if (p.full_price !== 0) {
            prices.push({
              name: 'Full',
              value: p.full_price,
            })
          }
          return { ...p, prices }
        })
      },
      changeInvoiceItem: (state, { payload }) => {
        state.new_invoice_item = {
          ...state.new_invoice_item,
          [payload.field]: payload.value,
        }
      },
      getInvoice: (state, { payload }) => {
        const invoice = parseInvoice(payload.invoice)
        state.event_details.invoice = invoice
        state.events = state.events.map(event => {
          if (event.ID === payload.event_id) {
            return { ...event, invoice }
          }
          return event
        })
      },
      getTeamHome: (state, { payload }) => {
        console.log('payload', payload)
        state.team_home = payload.team
      },
      getTeamAway: (state, { payload }) => {
        state.team_away = payload.team
      },
      selectEvent: (state, { payload }) => {
        state.event_details = {
          ...initialState.event_details,
          ...payload.event,
          edit: true,
          product_type: payload.event.product_type.name,
        }
      },
      openNewEvent: (state, { payload }) => {
        state.step = 1
        state.event_details = {
          ...initialState.event_details,
          ...payload.event,
        }
      },
      togglePaymentModal: (state, { payload }) => {
        state.payment_modal_open = payload.open
      },
      updatePayment: (state, { payload }) => {
        state.payment_form = {
          ...state.payment_form,
          [payload.field]: payload.value,
        }
      },
      getDiscounts: (state, { payload }) => {
        state.discounts = payload.discounts
      },
      toggleDiscountModal: (state, { payload }) => {
        state.discount_modal_open = payload.open
      },
      updateDiscountID: (state, { payload }) => {
        state.discount_id = payload.id
      },
      updateRecurringEvent: (state, { payload }) => {
        state.event_details.repeat_events =
          state.event_details.repeat_events.map((el, i) => {
            if (i === payload.i) {
              let start_date = el.start_date,
                end_date = el.end_date
              if (payload.field === 'start_date') {
                end_date = payload.value + (end_date - start_date)
              }
              return {
                ...el,
                [payload.field]: payload.value,
                to_check: true,
                end_date,
              }
            }
            return el
          })
      },
      deleteRecurringEvent: (state, { payload }) => {
        state.event_details.repeat_events =
          state.event_details.repeat_events.filter((val, i) => i !== payload.i)
      },
      notifyRepeatChange: (state, { payload }) => {
        if (payload) {
          state.event_details.repeat_events = []
        }
        state.event_details.repeat_change = payload
      },
      setSoccernautParticipants: (state, { payload }) => {
        state.soccernaut_participants = payload.participants
      },
      setPickupPlayers: (state, { payload }) => {
        state.pickup_players = payload.pickup_players.sort((a, b) => {
          const a_str = a.first_name + a.last_name,
            b_str = b.first_name + b.last_name
          if (a_str > b_str) return 1
          if (a_str < b_str) return -1
          return 0
        })
      },
      checkIn: (state, { payload }) => {
        state.pickup_players = state.pickup_players.map(player => {
          if (player.ID === payload.id) {
            return { ...player, checked_in: payload.checked_in }
          }
          return player
        })
      },
      changeTabMenu: (state, { payload }) => {
        state.tab_menu = payload.tab
      },
      changeTabCustomer: (state, { payload }) => {
        state.tab_customer = payload.tab
      },
      setHealthChecks: (state, { payload }) => {
        state.health_checks = payload.health_checks
      },
      clearEvents: (state, { payload }) => {
        state.event_details = initialState.event_details
      },
    },
  }),
  actions = calendarSlice.actions

export { calendarSlice, actions }
