import api from 'api'
import moment from 'moment'

import { momentDateTimeToUnix, unixToDateTime } from './../helper'
import { changeLoading, dispatchError } from './../common/actions'
import { actions } from './redux'
import { calendarSlice } from './../calendar/redux'
import { getInvoiceByContractID } from 'invoice/actions'
import { createOrUpdateCustomerReturn } from 'customer/actions'
import { actions as customerActions } from 'customer/redux'

export const getContracts = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return api.contract
    .get()
    .then(contract => {
      dispatch(
        actions.getContracts({
          contracts: contract.sort((a, b) => {
            if (a.ID > b.ID) return 1
            if (a.ID < b.ID) return -1
            return 0
          }),
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const searchContracts = by_customer => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { facility_ids, product_type_id, last_event_date, query } =
    getState().contract.search
  const { facility_id, is_admin } = getState().user
  let body = {}
  if (!is_admin) {
    body.facility_id = parseInt(facility_id)
  } else {
    if (facility_ids && facility_ids.length) {
      body.facility_ids = facility_ids
    }
  }
  if (product_type_id) {
    body.product_type_id = product_type_id
  }
  if (last_event_date) {
    body.last_event_date = last_event_date
  }
  if (query && query.trim().length) {
    body.query = query
    if (by_customer) {
      body.by_customer = 1
    }
  }
  return api.contract
    .search({ query: body })
    .then(contracts => {
      dispatch(
        actions.getContracts({
          contracts: contracts.sort((a, b) => {
            if (a.ID > b.ID) return 1
            if (a.ID < b.ID) return -1
            return 0
          }),
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const _getContract = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return api.contract
    .getSingle(id)
    .then(contract => {
      dispatch(actions.getContract({ contract }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getContract = id => (dispatch, getState) =>
  dispatch(_getContract(id))

export const createContract = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { new_contract } = getState().contract
  const { customer, creation } = getState().customer
  let product_id = 0,
    fee = 0
  if (!new_contract.fee_id) {
    return dispatch(dispatchError('Please select a valid fee'))
  }
  if (new_contract.fee_id !== -1) {
    const spl = new_contract.fee_id.split(';')
    product_id = parseInt(spl[0])
    fee = parseFloat(spl[1])
  } else {
    fee = parseFloat(new_contract.fee)
  }
  let body = {
    facility_id: new_contract.facility_id,
    name: new_contract.name,
    comment: new_contract.comment,
    product_type_id: new_contract.product_type_id,
    product_id,
    fee,
    deposit: parseFloat(new_contract.percentage),
  }
  let { first_name, last_name, email, phone } = creation
  let p = Promise.resolve(customer?.ID)
  if (first_name && last_name && email) {
    let customer_body = {
      first_name,
      last_name,
      email,
      facility_id: new_contract.facility_id,
      customer_type_id: 1,
    }
    if (phone) customer_body.hubspot = { phone }

    p = createOrUpdateCustomerReturn(customer_body)
  }
  p.then(id => {
    if (!id)
      return dispatch(dispatchError({ message: 'Error adding customer' }))
    body.customer_id = id

    return api.contract.create(body).then(contract => {
      dispatch(customerActions.clearNewCustomerDetails())
      window.location = `/contract/${contract.ID}`
    })
  }).catch(e => dispatch(dispatchError(e)))
}

export const openEventCreationModal = () => (dispatch, getState) => {
  const { contract } = getState().contract
  dispatch(
    calendarSlice.actions.changeEventDetails({
      field: 'product_type_id',
      value: contract.product_type_id,
    })
  )
  dispatch(
    calendarSlice.actions.changeEventDetails({
      field: 'product_type',
      value: contract.product_type.name,
    })
  )
  dispatch(actions.toggleEventCreationModal({ open: true }))
}

export const openEventEditModal = event => (dispatch, getState) => {
  dispatch(calendarSlice.actions.getEvent({ event }))
  const { contract } = getState().contract
  dispatch(
    calendarSlice.actions.changeEventDetails({
      field: 'product_type_id',
      value: contract.product_type_id,
    })
  )
  dispatch(
    calendarSlice.actions.changeEventDetails({
      field: 'product_type',
      value: contract.product_type.name,
    })
  )
  dispatch(actions.toggleEventEditModal({ open: true }))
}

export const getProducts = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { product_type_id } = getState().contract.contract,
    { facility_id } = getState().user
  let actual_product_type = product_type_id
  if (product_type_id === 2) {
    // block booking and on-demand share the same products
    actual_product_type = 1
  }
  return api.product
    .getByID(actual_product_type, facility_id)
    .then(products => {
      dispatch(actions.getProducts({ products }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getFees =
  (product_type_id, facility_id) => (dispatch, getState) => {
    dispatch(changeLoading(true))
    let current_facility_id = facility_id
    if (!current_facility_id) {
      current_facility_id = getState().user.facility_id
    }
    let actual_product_type = product_type_id
    if (product_type_id === 2) {
      // block booking and on-demand share the same products
      actual_product_type = 1
    }
    return api.product
      .getPricesByID(actual_product_type, current_facility_id)
      .then(prices => {
        dispatch(
          actions.getFees({
            fees: prices.map(fee => ({
              ...fee,
              id: `${fee.product_id};${fee.amount}`,
            })),
          })
        )
        dispatch(changeLoading(false))
      })
      .catch(e => dispatch(dispatchError(e)))
  }

const addInvoice = invoice_item => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { contract } = getState().contract
  const body = {
    date: momentDateTimeToUnix(moment()),
    customer_id: contract.customer_id,
    contract_id: contract.ID,
    invoice_type_id: 1,
    invoice_items: [invoice_item],
    product_type_id: contract.product_type_id,
    facility_id: contract.facility_id,
  }
  return api.invoice
    .create(body)
    .then(() => {
      dispatch(getInvoiceByContractID(contract.ID))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const deleteInvoiceItem = invoice_item_id => {
  return api.invoice.deleteInvoiceItem(invoice_item_id)
}

const runInvoiceUpdate = forced_map => (dispatch, getState) => {
  dispatch(changeLoading(true))
  let { ID, product_id, fee, events } = getState().contract.contract
  if (forced_map && 'product_id' in forced_map) {
    product_id = forced_map.product_id
  }
  if (forced_map && forced_map.fee) {
    fee = parseFloat(forced_map.fee)
  }
  let invoice = getState().invoice.invoice,
    invoice_item = {
      product_id,
      price: fee,
    }
  if (forced_map && forced_map.events) {
    events = forced_map.events
  }
  let quantity = events.length
  if (product_id === 0) {
    // Manual fee => count per hour
    quantity = events.reduce(
      (a, b) => a + (b.end_date - b.start_date) / 3600,
      0
    )
  }
  invoice_item.quantity = quantity
  if (!invoice) {
    return dispatch(addInvoice(invoice_item))
  }
  if (!invoice.ID) {
    return dispatch(addInvoice(invoice_item))
  }
  invoice_item.invoice_id = invoice.ID
  let p = Promise.resolve()
  if (invoice.invoice_items.length) {
    // Check but should only have 1 max length
    p = Promise.all(
      invoice.invoice_items.map(invoice_item =>
        deleteInvoiceItem(invoice_item.ID)
      )
    )
  }
  return p
    .then(() => {
      if (invoice_item.quantity === 0) {
        // deleted all events
        return dispatch(getInvoiceByContractID(ID))
      }
      return api.invoice.createInvoiceItem(invoice_item).then(() => {
        dispatch(getInvoiceByContractID(ID))
      })
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const saveContractEvents = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const event_details = getState().calendar.event_details,
    moment_start = unixToDateTime(event_details.start_time),
    moment_end = unixToDateTime(event_details.end_time),
    customer_id = getState().contract.contract.customer_id,
    contract_id = getState().contract.contract.ID,
    comment = `Contract #${contract_id}`,
    existing_events = getState().contract.contract.events
  let bodies = []
  if (!event_details.repeat_events.length) {
    // Single event, otherwise it's already included in the repeat events
    bodies = [
      {
        field_id: event_details.field_id,
        product_type_id: event_details.product_type_id,
        start_date: momentDateTimeToUnix(moment_start),
        end_date: momentDateTimeToUnix(moment_end),
        comment,
        customer_id,
        contract_id,
      },
    ]
  }
  if (event_details.repeat_events.filter(e => !e.is_ok).length > 0) {
    return dispatch(
      dispatchError('Please check that all events have free spots')
    )
  }
  event_details.repeat_events.forEach(event => {
    bodies.push({
      field_id: event.field_id,
      product_type_id: event.product_type_id,
      start_date: event.start_date,
      end_date: event.end_date,
      comment,
      customer_id,
      contract_id,
    })
  })
  return Promise.all(bodies.map(api.event.create))
    .then(() => {
      dispatch(_voidContractDoc())
      dispatch(_getContract(contract_id))
      dispatch(runInvoiceUpdate({ events: existing_events.concat(bodies) }))
    })
    .catch(e => {
      dispatch(dispatchError(e))
    })
}

export const editContractEvent = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const contract = getState().contract.contract,
    event_details = getState().calendar.event_details,
    moment_start = unixToDateTime(event_details.start_time),
    moment_end = unixToDateTime(event_details.end_time),
    customer_id = contract.customer_id,
    contract_id = contract.ID,
    comment = `Contract #${contract_id}`,
    body = {
      field_id: event_details.field_id,
      product_type_id: event_details.product_type_id,
      start_date: momentDateTimeToUnix(moment_start),
      end_date: momentDateTimeToUnix(moment_end),
      comment,
      customer_id,
      contract_id,
      field: getState().calendar.fields.find(
        f => f.ID === event_details.field_id
      ),
      product_type: getState().common.product_types.find(
        p => p.ID === event_details.product_type_id
      ),
    }
  return api.event
    .update(event_details.ID, body)
    .then(() => {
      dispatch(_voidContractDoc())
      dispatch(
        actions.updateEvent({
          id: event_details.ID,
          details: body,
        })
      )
      dispatch(runInvoiceUpdate())
      dispatch(actions.toggleEventCreationModal({ open: false }))
    })
    .catch(e => {
      dispatch(dispatchError(e.response.data))
    })
}

export const toggleCreationModal =
  ({ open }) =>
  (dispatch, getState) => {
    const { facility_id } = getState().user
    dispatch(actions.toggleCreationModal({ facility_id, open }))
  }

export const deleteEvent = event => (dispatch, getState) => {
  return api.event.delete(event.ID).then(() => {
    dispatch(actions.deleteEvent({ id: event.ID }))
    dispatch(_voidContractDoc())
    dispatch(runInvoiceUpdate())
  })
}

export const updateContractFee = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { new_contract } = getState().contract,
    contract = getState().contract.contract,
    { ID } = getState().contract.contract
  let product_id = 0,
    fee = 0
  if (new_contract.fee_id !== -1) {
    const spl = new_contract.fee_id.split(';')
    product_id = parseInt(spl[0])
    fee = parseFloat(spl[1])
  } else {
    fee = parseFloat(new_contract.fee)
  }
  return api.contract
    .update(ID, {
      ...contract,
      ApplyProcessingFee: new_contract.ApplyProcessingFee,
    })
    .then(() => {
      api.contract.updateFee(ID, { product_id, fee }).then(() => {
        dispatch(_voidContractDoc())
        dispatch(_getContract(ID))
        dispatch(
          runInvoiceUpdate({
            product_id,
            fee,
          })
        )
        dispatch(actions.toggleFeeModal({ open: false }))
      })
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const generateContractDoc = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const contract = getState().contract.contract
  if (contract.doc !== '') return
  return api.contract
    .generateDoc(contract.ID)
    .then(doc => {
      dispatch(changeLoading(false))
      dispatch(actions.setContractDoc({ doc }))
      dispatch(actions.openAlert({ message: 'New document generated' }))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const sendContractDoc = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { ID } = getState().contract.contract
  return api.contract
    .sendDoc(ID)
    .then(() => {
      dispatch(changeLoading(false))
      dispatch(actions.sendContractDoc())
      dispatch(actions.openAlert({ message: 'Contract sent via email' }))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const _voidContractDoc = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { ID, doc } = getState().contract.contract
  if (doc === '') return
  return api.contract
    .voidDoc(ID)
    .then(() => {
      dispatch(changeLoading(false))
      dispatch(actions.voidContractDoc())
      dispatch(actions.openAlert({ message: 'Removed previous contract' }))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const voidContractDoc = () => (dispatch, getState) => {
  dispatch(_voidContractDoc())
}

export const deleteContract = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return api.contract
    .delete(id)
    .then(() => {
      window.location = `/contract/`
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const deleteAllEvents = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const _del = e => api.event.delete(e.ID)
  return Promise.all(getState().contract.contract.events.map(_del))
    .then(() => {
      return Promise.all(
        getState().invoice.invoice.invoice_items.map(invoice_item =>
          deleteInvoiceItem(invoice_item.ID)
        )
      )
    })
    .then(() => {
      dispatch(_getContract(id))
      dispatch(getInvoiceByContractID(id))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const updateProductType = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { new_contract } = getState().contract,
    { ID, events } = getState().contract.contract
  return api.contract
    .update(ID, { product_type_id: new_contract.product_type_id })
    .then(() => {
      return Promise.all(
        events.map(event => {
          return api.event.update(event.ID, {
            product_type_id: new_contract.product_type_id,
          })
        })
      )
    })
    .then(() => {
      dispatch(changeLoading(false))
      const new_p_type = getState().common.product_types.find(
        p => p.ID === new_contract.product_type_id
      )
      dispatch(actions.updatePtype({ new_p_type }))
      dispatch(actions.togglePtypeModal({ open: false }))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const updateName = name => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { ID } = getState().contract.contract
  return api.contract
    .update(ID, { name })
    .then(() => dispatch(changeLoading(false)))
    .catch(e => dispatch(dispatchError(e)))
}
