import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Select from 'react-select'
import Button from '@material-ui/core/Button'
import TextField from 'common/components/FastTextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'

import { payment_options } from './../../consts'
import { updateSearch, searchInvoices } from './../actions'
import { actions } from './../redux'
import { unixToDateTime, momentDateTimeSecondsToUnix } from '../../helper'
import { searchCustomers } from 'customer/actions'
import moment from 'moment'

const styles = theme => ({
  paper: {
    maxHeight: 650,
    overflow: 'scroll',
  },
})

class Filters extends React.Component {
  constructor(props) {
    super(props)
    this.state = { focusedInput: null }
  }

  render() {
    return (
      <Grid container spacing={2} alignItems="center" justify="flex-start">
        <Grid item xs={3} style={{
          display:'flex',
          flexDirection:'row',
        }}>
          <DatePickerComponent
            id="start_date"
            placeholder="Start date"
            selectedValue={this.props.start_date ? unixToDateTime(this.props.start_date) : null}
            onChange={e => {
              this.props.updateSearch(
                'start_date',
                momentDateTimeSecondsToUnix(
                  moment(e.value).hour(0).minute(0).second(0)
                )
              )
            }}
            style={{ textAlign: 'center' }}
          />

          <DatePickerComponent
            id="end_date"
            placeholder="End date"
            selectedValue={this.props.end_date ? unixToDateTime(this.props.end_date) : null}
            onChange={e => {
              this.props.updateSearch(
                'end_date',
                momentDateTimeSecondsToUnix(
                  moment(e.value).hour(23).minute(59).second(59)
                )
              )
            }}
            style={{ textAlign: 'center' }}
          />
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          <Select
            options={this.props.facilities}
            placeholder="Facility"
            value={
              this.props.facility_id &&
              this.props.facilities.find(
                f => f.value === this.props.facility_id
              )
            }
            onChange={v => this.props.updateSearch('facility_id', v && v.value)}
            isClearable={true}
          />
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          <Select
            options={this.props.product_types}
            placeholder="Product type"
            value={
              this.props.product_type_id &&
              this.props.product_types.find(
                p => p.value === this.props.product_type_id
              )
            }
            onChange={v =>
              this.props.updateSearch('product_type_id', v && v.value)
            }
            isClearable={true}
          />
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          <Select
            options={payment_options}
            placeholder="Payment type"
            value={
              this.props.payment_type &&
              payment_options.find(p => p.value === this.props.payment_type)
            }
            onChange={v =>
              this.props.updateSearch('payment_type', v && v.value)
            }
            isClearable={true}
          />
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'center' }}>
          <TextField
            label="Square transaction id"
            fullWidth
            value={this.props.square_id}
            onChange={e => this.props.updateSearch('square_id', e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            freeSolo
            options={this.props.customers}
            getOptionLabel={option =>
              `${option.first_name} ${option.last_name} (${option.email})`
            }
            renderOption={option => (
              <p>
                {option.first_name} {option.last_name}{' '}
                {option.hubspot && option.hubspot.gender && (
                  <span>({option.hubspot.gender})</span>
                )}
                <br />
                <span>
                  email=
                  {option.email}
                </span>
                {option.hubspot && option.hubspot.phone && (
                  <span>
                    <br />
                    phone=
                    {option.hubspot.phone}
                  </span>
                )}
                {option.hubspot && option.hubspot.state && (
                  <span>
                    <br />
                    state=
                    {option.hubspot.state}
                  </span>
                )}
              </p>
            )}
            renderInput={params => (
              <TextField
                {...params}
                label="Customer"
                variant="outlined"
                fullWidth
                onChange={e => this.props.searchCustomers(e.target.value)}
              />
            )}
            value={this.props.customer}
            onChange={(e, c) => this.props.updateSearch('customer', c)}
          />
        </Grid>
        <Grid item xs={1}>
          <Button
            className="save-btn"
            style={{ width: '100%' }}
            onClick={() => this.props.searchInvoices()}
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="outlined"
            color="secondary"
            style={{ width: '100%' }}
            onClick={() => this.props.createInvoice()}
          >
            Create
          </Button>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.invoice.search,
  facilities: state.common.filter_facilities,
  product_types: state.common.product_types.map(p => ({
    label: p.name,
    value: p.ID,
  })),
  invoices: state.invoice.invoices,
  is_admin: state.user.user_type === 'admin',
  customers: state.customer.customers,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateSearch: (k, v) => dispatch(updateSearch(k, v)),
  searchInvoices: () => dispatch(searchInvoices()),
  createInvoice: () => dispatch(actions.toggleInvoiceModal({ open: true })),
  searchCustomers: q => dispatch(searchCustomers(q)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Filters))
