import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import Select from 'react-select'

import FastTextField from 'common/components/FastTextField'

import { getProductTypes, getSeasons, saveProductType } from 'resources/actions'
import { actions } from 'resources/redux'
import { TextField } from '@material-ui/core'
let product_types_categories = [
  { label: 'event', value: '1' },
  { label: 'aux', value: '2' },
  { label: 'block', value: '3' },
  '',
]
const NewProductTypeDialog = () => {
  const { open, name, category, reporting, gl, id, ranking } = useSelector(
      state => state.resources.product_type
    ),
    dispatch = useDispatch(),
    updateProductType = (k, v) =>
      dispatch(actions.updateProductType({ key: k, value: v })),
    handleClose = () => updateProductType('open', false),
    _saveProductType = () => dispatch(saveProductType())

  useEffect(() => {
    dispatch(getProductTypes())
    dispatch(getSeasons())
  }, [])
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        {id ? 'Create Product Type' : 'Update Product Type'}
        <IconButton
          style={{
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="row" alignItems="flex-start" spacing={2}>
          <Grid item xs={6}>
            <FastTextField
              label="Name"
              variant="outlined"
              value={name}
              onChange={e => updateProductType('name', e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={3}>
            <FastTextField
              label="Reporting"
              variant="outlined"
              value={reporting}
              onChange={e => updateProductType('reporting', e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={3}>
            Event Category
            <Select
              options={product_types_categories}
              placeholder="Category *"
              value={
                category &&
                product_types_categories.find(p => p.label === category)
              }
              onChange={v => updateProductType('category', v.label)}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </Grid>

          <Grid item xs={6}>
            <FastTextField
              label="GL code"
              variant="outlined"
              value={gl}
              onChange={e => updateProductType('gl', e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="order on dropdown"
              variant="outlined"
              value={ranking}
              onChange={e =>
                updateProductType('ranking', parseInt(e.target.value))
              }
              fullWidth
              type="number"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => _saveProductType()} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewProductTypeDialog
