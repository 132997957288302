import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  resource_type: 'field',
  facility_id: 1,
  fields: [],
  facility: {
    id: null,
    name: null,
    nickname: null,
    origin: null,
    address: null,
    tax_rate: 0,
    open: false,
  },
  field: {
    number: null,
    name: null,
    new_field_type_id: null,
    weekday_open_time: null,
    weekday_close_time: null,
    weekend_open_time: null,
    weekend_close_time: null,
    weekday_peak_time_start: null,
    weekday_peak_time_end: null,
    weekend_peak_time_start: null,
    weekend_peak_time_end: null,
    open: false,
  },
  tax: {
    id: null,
    name: null,
    value: 0,
    open: false,
  },
  integration: {
    id: null,
    name: null,
    type: null,
    open: false,
  },
  integrations: [],
  fee: {
    id: null,
    name: null,
    value: 0,
    open: false,
  },
  age_groups: [],
  age_group: {
    id: null,
    name: null,
    open: false,
  },
  product_types: [],
  seasons: [],
  gl: {
    id: null,
    code: null,
    product_type_id: null,
    season_id: null,
    open: false,
  },
  product_type: {
    id: null,
    name: null,
    category: null,
    reporting: null,
    gl: null,
    ranking: null,
    color: null,
    open: false,
  },
  field_types: [],
  field_type: {
    id: null,
    name: null,
    capacity: null,
    is_outdoors: false,
    open: false,
  },
}

const resourceSlice = createSlice({
    name: 'staff',
    initialState,
    reducers: {
      updateState: (state, { payload }) => {
        state[payload.key] = payload.value
      },
      updateFacility: (state, { payload }) => {
        if (payload.reset) {
          state.facility = initialState.facility
        }
        state.facility = {
          ...state.facility,
          [payload.key]: payload.value,
        }
      },
      updateField: (state, { payload }) => {
        if (payload.reset) {
          state.field = initialState.field
        }
        state.field = {
          ...state.field,
          [payload.key]: payload.value,
        }
      },
      updateTax: (state, { payload }) => {
        if (payload.reset) {
          state.tax = initialState.tax
        }
        state.tax = {
          ...state.tax,
          [payload.key]: payload.value,
        }
      },
      updateIntegration: (state, { payload }) => {
        if (payload.reset) {
          state.integration = initialState.integration
        }
        if (payload.key === 'data') {
          state.integration = {
            ...state.integration,
            ...payload.value,
          }
        } else {
          state.integration = {
            ...state.integration,
            [payload.key]: payload.value,
          }
        }
      },
      updateFee: (state, { payload }) => {
        if (payload.reset) {
          state.fee = initialState.fee
        }
        state.fee = {
          ...state.fee,
          [payload.key]: payload.value,
        }
      },
      updateAgeGroup: (state, { payload }) => {
        if (payload.reset) {
          state.age_group = initialState.age_group
        }
        state.age_group = {
          ...state.age_group,
          [payload.key]: payload.value,
        }
      },
      updateGL: (state, { payload }) => {
        if (payload.reset) {
          state.gl = initialState.gl
        }
        state.gl = {
          ...state.gl,
          [payload.key]: payload.value,
        }
      },
      resetProductType: state => {
        state.product_type = initialState.product_type
      },
      updateProductType: (state, { payload }) => {
        if (payload.reset) {
          state.gl = initialState.product_type
        }
        state.product_type = {
          ...state.product_type,
          [payload.key]: payload.value,
        }
      },
      setProductType: (state, { payload }) => {
        state.product_type = payload
      },
      updateFieldType: (state, { payload }) => {
        if (payload.reset) {
          state.field_type = initialState.field_type
        }
        state.field_type = {
          ...state.field_type,
          [payload.key]: payload.value,
        }
      },
    },
  }),
  actions = resourceSlice.actions

export { resourceSlice, actions }
