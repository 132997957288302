import React from 'react'
import { connect } from 'react-redux'

import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Typography,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import ProfileIcon from '@material-ui/icons/AssignmentInd'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import {
  selectFamilyMember,
  updateSearchQuery,
  searchCustomers,
  addFamilyMember,
  confirmRemove,
} from './../../actions'
import { openInNewTab } from './../../../helper'
import { colors } from './../../../consts'

const CustomerFamily = ({
  customer,
  customers,
  searchCustomers,
  selectFamilyMember,
  family,
  family_member,
  addFamilyMember,
  removeFamilyMember,
}) => {
  return (
    <Card elevation={0}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Family
        </Typography>
        <form autoComplete="off" noValidate>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item md={8} xs={12}>
              <Autocomplete
                options={customers}
                getOptionLabel={option =>
                  `${option.first_name} ${option.last_name} (${option.facility.name}) - ${option.email}`
                }
                value={family_member}
                onChange={(e, c) => selectFamilyMember(c)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Search customer"
                    fullWidth={true}
                    variant="outlined"
                    onChange={e => searchCustomers(e.target.value)}
                  />
                )}
                fullWidth
              />
            </Grid>
            <Grid item md={2} xs={12} style={{ paddingTop: '20px' }}>
              <Button
                onClick={addFamilyMember}
                style={{
                  color: colors.blue_strong,
                  backgroundColor: colors.blue_mild,
                }}
              >
                <b>Add</b>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }}>Name</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>Email</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      Family
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {family.map(fam => (
                    <TableRow key={fam.ID}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ textAlign: 'center' }}
                      >
                        {fam.name}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <u>{fam.contact}</u>
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {fam.relation}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <IconButton
                          onClick={() =>
                            openInNewTab(`/customer/${fam.customer_id}`)
                          }
                        >
                          <ProfileIcon color="primary" fontSize="small" />
                        </IconButton>
                        <IconButton onClick={() => removeFamilyMember(fam.ID)}>
                          <DeleteIcon
                            style={{
                              color: colors.red_strong,
                              backgroundColor: colors.red_mild,
                            }}
                            fontSize="small"
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

const mapStateToProps = (state, ownProps) => ({
  customer: state.customer.customer,
  customers: state.customer.customers,
  family: state.customer.family,
  family_member: state.customer.family_member,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectFamilyMember: m => dispatch(selectFamilyMember(m)),
  searchCustomers: s => {
    dispatch(updateSearchQuery(s))
    dispatch(searchCustomers())
  },
  addFamilyMember: () => dispatch(addFamilyMember()),
  removeFamilyMember: id => dispatch(confirmRemove(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerFamily)
